<template>
    <div>
        <search-bar :objects="borrowables" @filter="onFilter" :fuse-options="fuseOptions"/>
        <b-row>
            <b-col cols="10">
                <multi-select placeholder="Tags à afficher"
                              v-model="selectedTags"
                              :options="availableTags"
                              :multiple="true"
                              :close-on-select="false"
                              :clear-on-select="true"
                              :preserve-search="false"
                              track-by="id"/>
            </b-col>
            <b-col cols="2" class="text-center th-icons">
                <font-awesome-icon :icon="listDisplay ? 'th' : 'th-list'"
                                   v-b-tooltip.hover="listDisplay ? 'Affichage bloc' : 'Affichage liste'"
                                   @click="listDisplay = !listDisplay"/>
            </b-col>
        </b-row>
        <hr/>
        <vue-masonry-wall :items="displayedBorrowables" :options="masonryWallOptions" @append="appendBorrowables"
                          v-if="!listDisplay">
            <template v-slot:default="{item}">
                <borrowable-item-block :borrowable="item" :type="type"/>
            </template>
        </vue-masonry-wall>
        <b-row v-else>
            <borrowable-item-row v-for="borrowable in filteredBorrowables"
                                 :key="type + '_' + borrowable.id"
                                 :borrowable="borrowable"
                                 :type="type" class="item-row"/>
        </b-row>
    </div>
</template>

<script>
import alert            from '@/util/alert';
import {apiPath}        from '@/util/http';
import {numberSortDesc} from '@/util/sort';

const MultiSelect         = () => import('@/components/MultiSelect');
const SearchBar           = () => import('@/components/SearchBar');
const BorrowableItemBlock = () => import('@/components/BorrowableItemBlock');
const BorrowableItemRow   = () => import('@/components/BorrowableItemRow');
const VueMasonryWall      = () => import('vue-masonry-wall/src/vue-masonry-wall');
//                                                        ^^^^^^^^^^^^^^^^^^^^^
// This isn't how you're suppose to import it, but when I yarn add it from my GitHub
// fork it doesn't work the standard way for some obscure reason

const BORROWABLES_LOADING_GRANULARITY = 30;

export default {
    name: 'BorrowableBlockList',
    components: {MultiSelect, SearchBar, BorrowableItemBlock, BorrowableItemRow, VueMasonryWall},
    props: {
        borrowables: {
            type: Array,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    data: () => ({
        listDisplay: false,
        availableTags: [],
        selectedTags: [],
        filteredBorrowables: [],
        fuseOptions: {
            keys: [
                {
                    name: 'nom',
                    weight: 0.6
                },
                {
                    name: 'description',
                    weight: 0.4
                }
            ]
        },
        displayedBorrowablesLength: BORROWABLES_LOADING_GRANULARITY,
        masonryWallOptions: {
            padding: {
                default: 10
            }
        }
    }),
    computed: {
        displayedBorrowables() {
            return this.filteredBorrowables.slice(0, Math.min(this.displayedBorrowablesLength, this.filteredBorrowables.length));
        }
    },
    methods: {
        onFilter(val) {
            this.filteredBorrowables = val;
        },
        tagFilter() {
            if (!this.selectedTags.length) {
                this.filteredBorrowables = this.borrowables;
                return;
            }

            let scores = this.borrowables.map(item => {
                // Each item gets assigned a score, corresponding to how many desired tags it matches
                let out = {score: 0, item: item};
                for (const tag of item.tags)
                    out.score += this.selectedTags.filter(({id}) => id === tag).length; // out += 0 or 1 each time

                return out;
            });

            // Irrelevant items are filtered
            scores = scores.filter(({score}) => score > 0);
            // Items are sorted by score
            scores = scores.sort((a, b) => numberSortDesc(a.score, b.score));

            // We extract only the items from our datastructure (don't need scores anymore)
            this.filteredBorrowables = scores.map(({item}) => item);
        },
        loadAvailableTags() {
            alert.loading()
                .then(() => this.axios.get(apiPath(`get_${this.type}_borrowable_tags`)))
                .then(response => this.availableTags = [{id: 0, nom: 'Catégories', $isDisabled: true}]
                    .concat(response.data.filter(({important}) => important))
                    .concat([{id: 0, nom: 'Tous les tags', $isDisabled: true}])
                    .concat(response.data.filter(({important}) => !important)))
                .catch(() => this.$toaster.error('Impossible de récupérer la liste des tags'))
                .finally(alert.stopLoading);
        },
        appendBorrowables() {
            this.displayedBorrowablesLength = Math.min(
                this.filteredBorrowables.length,
                this.displayedBorrowablesLength + BORROWABLES_LOADING_GRANULARITY
            );
        }
    },
    watch: {
        borrowables(val) {
            this.borrowables = val;
            this.tagFilter();
        },
        selectedTags(val) {
            this.selectedTags = val;
            this.tagFilter();
        }
    },
    mounted() {
        this.loadAvailableTags();
        this.tagFilter();
    }
};
</script>

<style lang="scss">
.th-icons {
    line-height: 43px;
    font-size: 30px;

    svg {
        cursor: pointer;
    }
}

.item-row {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.item-row:last-of-type {
    border-bottom: none;
}
</style>